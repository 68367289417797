import { skipToken } from "@reduxjs/toolkit/query";
import { useGetBrewMenuByIdQuery } from "../../../api/brewMenu/brewMenuMenuApi";
import { selectActiveBmSubId } from "../../../features/orgGroups/state/orgGroupSelectors";
import { useAppSelector } from "../../../state/store";

const token = (
	menuId: string,
	bmSubId: string,
):
	| {
			itemId: string;
			parentId: string;
	  }
	| undefined => {
	if (menuId && bmSubId) {
		return {
			itemId: menuId,
			parentId: bmSubId,
		};
	}

	return undefined;
};

export const useBrewMenuDisplayMenu = (menuId: string | undefined) => {
	const bmSubId = useAppSelector(selectActiveBmSubId);

	const { data, isError, isFetching, isSuccess, refetch } =
		useGetBrewMenuByIdQuery(token(menuId, bmSubId) ?? skipToken);

	return {
		menu: data,
		isErrorBrewMenuDisplayMenu: isError,
		isFetchingBrewMenuDisplayMenu: isFetching,
		isSuccessBrewMenuDisplayMenu: isSuccess,
		refetchGetBrewMenuByIdQuery: refetch,
	};
};
