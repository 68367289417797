import { OrganizationGroup, } from "types";
import { baseApi } from "../../../api/baseApi";
import { datadogLocalErrorLog } from "../../../datadog/datadogErrorLogger";
import { getMyOrganizationGroupsQuery } from "../queries/getMyOrganizationGroupsQuery";

const orgGroupsApiLog = datadogLocalErrorLog("orgGroupsApiLog");

const orgGroupsApi = baseApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getMyOrganizationGroups: builder.query<
      OrganizationGroup[],
      void
    >({
      query: () => ({
        query: getMyOrganizationGroupsQuery,
      }),
      transformErrorResponse: orgGroupsApiLog("getOrganizationGroups"),
    }),
  })
})

const { useGetMyOrganizationGroupsQuery } = orgGroupsApi;

export { orgGroupsApi, useGetMyOrganizationGroupsQuery };
