import { skipToken } from "@reduxjs/toolkit/query"
import { useGetBrewMenuDisplaysByBMSubIdQuery } from "../../../api/brewMenu/brewMenuDisplayApi"
import { selectActiveBmSubId } from "../../../features/orgGroups/state/orgGroupSelectors"
import { useAppSelector } from "../../../state/store"
import { brewMenuSubscriptionToken } from "../utilities/brewMenuSubscriptionToken"

export const useBrewMenuDisplays = () => {
  const bmSubId = useAppSelector(selectActiveBmSubId)

  const { data, isError, isFetching, isLoading, isSuccess } =
    useGetBrewMenuDisplaysByBMSubIdQuery(brewMenuSubscriptionToken(bmSubId) ?? skipToken)

  return {
    brewMenuDisplays: (data && data.items) || [],
    isErrorBrewMenuDisplays: isError,
    isFetchingBrewMenuDisplays: isFetching,
    isLoadingBrewMenuDisplays: isLoading,
    isSuccessBrewMenuDisplays: isSuccess,
  }
}
