"use client"

import { skipToken } from "@reduxjs/toolkit/query"
import { BrewMenuSubscription, } from "types"
import { useGetBrewMenuSubscriptionByIdQuery } from "../../api"
import { selectActiveBmSubId } from "../../features/orgGroups/state/orgGroupSelectors"
import { useAppSelector } from "../../state/store"
import { brewMenuSubscriptionToken } from "./utilities/brewMenuSubscriptionToken"

export interface useBrewmenuSubscriptionHook {
  isLoadingBrewmenuSubscription: boolean
  isErrorBrewmenuSubscription: boolean
  isFetchingBrewmenuSubscription: boolean
  isSuccessBrewmenuSubscription: boolean
  brewMenuSubscription: BrewMenuSubscription
}

export const useBrewmenuSubscription = (): useBrewmenuSubscriptionHook => {
  const bmSubId = useAppSelector(selectActiveBmSubId)

  const {
    data: brewMenuSubscription,
    isLoading: isLoadingBrewmenuSubscription,
    isError: isErrorBrewmenuSubscription,
    isFetching: isFetchingBrewmenuSubscription,
    isSuccess: isSuccessBrewmenuSubscription
  } = useGetBrewMenuSubscriptionByIdQuery(brewMenuSubscriptionToken(bmSubId) ?? skipToken)

  return {
    brewMenuSubscription,
    isLoadingBrewmenuSubscription,
    isErrorBrewmenuSubscription,
    isFetchingBrewmenuSubscription,
    isSuccessBrewmenuSubscription
  }
}
