import { useGetDiscountPresetsQuery } from "../../../api";
import { selectActiveBmSubId } from "../../../features/orgGroups/state/orgGroupSelectors";
import { useAppSelector } from "../../../state/store";

export const useDiscountPresets = () => {
	const bmSubId = useAppSelector(selectActiveBmSubId);

	const {
		data: discountPresets,
		isLoading: isLoadingDiscountPresets,
		isError: isErrorDiscountPresets,
		isFetching: isFetchingDiscountPresets,
		isSuccess: isSuccessDiscountPresets,
	} = useGetDiscountPresetsQuery({ bmSubId }, { skip: !bmSubId });

	return {
		discountPresets,
		isLoadingDiscountPresets,
		isErrorDiscountPresets,
		isFetchingDiscountPresets,
		isSuccessDiscountPresets,
	};
};
