'use client'

import { skipToken } from "@reduxjs/toolkit/query"
import { useGetOrganizationPricingPresetsQuery } from "../../api"
import { useBLXUser, } from "../../state"

const token = (organizationId: string) => {
  if (organizationId && organizationId.length > 0) return { organizationId }

  return undefined
}

export const usePricingPresets = () => {
  const { orgId } = useBLXUser()

  const {
    data: pricingPresets,
    isLoading: isLoadingPricingPresets,
    isError: isErrorPricingPresets,
    isFetching: isFetchingPricingPresets,
    isSuccess: isSuccessPricingPresets,
  } = useGetOrganizationPricingPresetsQuery(token(orgId) ?? skipToken)

  return {
    pricingPresets,
    isLoadingPricingPresets,
    isErrorPricingPresets,
    isFetchingPricingPresets,
    isSuccessPricingPresets,
  }
}
