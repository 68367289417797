"use client";
import { toastActions } from "notifications/src/toasts";
import { useState } from "react";
import {
	BrewMenuCustomProduct,
	CompositeProduct,
	InputCreateMenuItem,
	MenuItemType,
	MenuSection,
	TapSensorPublic,
} from "types";
import { useUpdateMenuSectionMutation } from "../../../api";
import {
	selectActiveBmSubId,
	selectActivePpSubId,
} from "../../../features/orgGroups/state/orgGroupSelectors";
import { useAppSelector } from "../../../state/store";
import { useBrewMenuMenuById } from "../menus/useBrewMenuMenuById";
import { useMenuItemsAndSectionMutations } from "./useMenuItemsAndSectionMutations";

type ProductUnionType =
	| BrewMenuCustomProduct
	| CompositeProduct
	| TapSensorPublic;

const isTapSensor = (item: ProductUnionType): item is TapSensorPublic => {
	return (
		Object.hasOwnProperty.call(item, "tappedKeg") &&
		item.__typename === "TapSensorPublic"
	);
};

const isCustomProduct = (
	item: ProductUnionType,
): item is BrewMenuCustomProduct => {
	return item.__typename === "BrewMenuCustomProduct";
};

const itemType = (item: ProductUnionType): MenuItemType => {
	if (Array.isArray(item)) return null;

	if (isTapSensor(item)) return MenuItemType.TapSensor;
	if (isCustomProduct(item)) return MenuItemType.CustomProduct;

	return MenuItemType.Product;
};

const parentId = (
	item: ProductUnionType,
	ppSubId: string | undefined,
): InputCreateMenuItem["parentId"] | undefined => {
	if (!isTapSensor(item) && !isCustomProduct(item)) {
		if (!item.brand?.id) throw new Error("Cannot add menu item without brand");
		return item.brand.id;
	}

	if (isTapSensor(item)) return ppSubId;
};

export const useNewMenuItem = (menuId: string) => {
	const [isLoading, setIsLoading] = useState(false);
	const { createMenuItem } = useMenuItemsAndSectionMutations();
	const [updateMenuSection] = useUpdateMenuSectionMutation();
	const bmSubId = useAppSelector(selectActiveBmSubId);
	const ppSubId = useAppSelector(selectActivePpSubId);
	const { getBrewMenuRefetch } = useBrewMenuMenuById(menuId);

	const createAndAddMenuItem = async (
		items: ProductUnionType[],
		section: MenuSection,
	) => {
		setIsLoading(true);
		let ids: string[] = [];
		try {
			let orderedData = items;
			/*
			 * Special step below to check if product array contains Tap Sensors
			 * And if it does, we arrange the taps in ascending order
			 */
			if (items[0]?.__typename === "TapSensorPublic") {
				const tapSensorData = items as TapSensorPublic[];
				orderedData = tapSensorData?.toSorted(
					(a, b) => a.tapNumber - b.tapNumber,
				);
			}
			const res = await Promise.all(
				(await orderedData.map(async (item) => {
					return await createMenuItem(
						{
							availability: {
								available: true,
							},
							itemId: item.id,
							menuItemType: itemType(item),
						},
						parentId(item, ppSubId),
					);
				})) as unknown as ProductUnionType[],
			);

			if (res) {
				ids = res.map((item) => item.id);
				await updateMenuSection({
					menuSectionId: section.id,
					bmSubId,
					menuItemIds: [...section.menuItems.map((item) => item.id), ...ids],
				});
			}
		} catch (error) {
			toastActions.addToast({
				variant: "error",
				title: error.message,
				duration: 6000,
			});
		} finally {
			await getBrewMenuRefetch();
			setIsLoading(false);
		}
	};

	return {
		createAndAddMenuItem,
		isLoading,
	};
};
