import { gql } from 'graphql-request';

export const getOrganizationByIdQuery = gql`
      query GetOrganizationById($input: inputGetOrganizationById!) {
            getOrganizationById(input: $input){
                  updatedDate
                  lastUpdatedBy
                  id
                  createdDate
                  createdBy
                  address {
                        city
                        country
                        postalCode
                        state
                        street
                        street2
                  }
                  brands {
                        address {
                              city
                              state
                        }
                        description
                        displayTitle
                        id
                        locations { 
                        id
                        title
                  }
                  products { 
                  id
                  title
            }
            
            slug
            title
            organization {
                  id
                  title
            }
            assets {
                  __typename
                  id
                  alt
                  type
                  updatedDate
                  createdDate
                  createdBy
                  bytes 
                  width
                  height
                  tags
                  ... on S3Asset {
                        path
                        filename
                  }
                  ... on CloudinaryAsset {
                        cloudinaryId
                        caption
                        category
                  }
            }
            
      }
      brewMenuSubscriptions {
            brandId
            id
            ppSubId
            slug
            subscriptionTier
            title
            menus {
                  id
            } 
            displays {
                  id
            }
      }
      contact {
            email
            phone
      }
      externalId
      hubspotId
      owner {
            id
            brewlogixSuperadmin
            contact {
                  email
                  phone
            }
            description
            familyName
            givenName
            jobTitle
            slug
            trustedUser
      }
      ppSubscriptions {
            address {
                  street
                  city
                  state
            }
            expirationDate
            hubSpotId
            id
            lastUpdatedBy
            locationType
            numberOfTapSensors
            slug
            tapSensors { 
            id
      }
      title
      updatedDate
      logo {
            updatedDate
            lastUpdatedBy
            id
            createdDate
            createdBy
            cloudinaryId
            # bmSubLogoAssignments { ...bmSubLogoAssignments }
            # menuItemAssetAssignments { ...menuItemAssetAssignments }
            # organizationLogoAssignments { ...organizationLogoAssignments }
            # ppSubLogoAssignments { ...ppSubLogoAssignments }
            shared
            tags
      }
}
logo {
      updatedDate
      lastUpdatedBy
      id
      createdDate
      createdBy
      cloudinaryId
      # bmSubLogoAssignments { ...bmSubLogoAssignments }
      # menuItemAssetAssignments { ...menuItemAssetAssignments }
      # organizationLogoAssignments { ...organizationLogoAssignments }
      # ppSubLogoAssignments { ...ppSubLogoAssignments }
      shared
      tags
}
cloudinaryAssetId
slug
status
title
users {
      id
      brewlogixSuperadmin
      contact {
            email
            phone
      }
      description
      familyName
      givenName
      jobTitle
      slug
      trustedUser
}
}
}
`
