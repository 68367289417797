import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { baseApi } from "../api";

// State slices
import orgGroupSlice from "../features/orgGroups/state/orgGroupSlice";
import applicationStateSlice from "./application/applicationState";
import persistedUserSlice from "./user/persistedUserSlice";
import persistedUserSubscriptionsSlice from "./user/persistedUserSubscriptionsSlice";

export const store = configureStore({
	reducer: {
		[baseApi.reducerPath]: baseApi.reducer,
		activeOrgGroup: orgGroupSlice.reducer,
		[applicationStateSlice.reducerPath]: applicationStateSlice.reducer,
		[persistedUserSlice.reducerPath]: persistedUserSlice.reducer,
		[persistedUserSubscriptionsSlice.reducerPath]:
			persistedUserSubscriptionsSlice.reducer,
	},
	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware({ immutableCheck: false }).concat(
			baseApi.middleware,
		);
	},
});

export const makeStore = () => store;

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof makeStore>;

export type StateSelector<T> = (state: RootState) => T;
export type AppDispatch = typeof store.dispatch;

// Selectors
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Dispatch
export const useAppDispatch: () => AppDispatch = useDispatch;
export const blxQ: AppDispatch = store.dispatch;

setupListeners(store.dispatch);
