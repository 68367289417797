import { AccountRequestRole } from "types"

import { useCreateAccountRequestMutation } from "../../api"
import { useBrewlogixUser } from "../../state"

export const useCreateAccountRequest = () => {
    const { userData } = useBrewlogixUser()
    const [createAccountRequest] = useCreateAccountRequestMutation()

    const createAccountRequestFn = async (args: {
        newBrandTitle?: string
        claimedBrand?: string
        requestedRole: AccountRequestRole
        notes: string
    }) => {
        if (!userData) {
            return
        }

        const { contact, givenName, familyName, id } = userData

        return createAccountRequest({
            contact,
            givenName,
            familyName,
            userId: id,
            ...args
        })
    }

    return {
        createAccountRequest: createAccountRequestFn
    }
}
