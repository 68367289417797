export const plGetProductByIdQuery = /* GraphQL */ `
query GetProductById($input: inputGetById!) {
	getProductById(input: $input){
		__typename
		abv
		alcoholFree
		assets {
			alt
			# brand { ...brand }
			bytes
			caption
			category
			createdBy
			createdDate
			height
			id
			lastUpdatedBy
			# locations { ...locations }
			path
			# products { ...products }
			shared
			tags
			type
			updatedDate
			width
            ... on CloudinaryAsset {
                cloudinaryId
            }
		}
		brand {
			# address { ...address }
			# assets { ...assets }
			baId
			# contact { ...contact }
			createdBy
			createdDate
			description
			displayTitle
			# featuredBrew { ...featuredBrew }
			guid
			id
			lastUpdatedBy
			# locations { ...locations }
			# mediaLibrary { ...mediaLibrary }
			# organization { ...organization }
			# products { ...products }
			slug
			# socialMedia { ...socialMedia }
			subtitle
			title
			# traits { ...traits }
			updatedDate
			website
			yearEstablished
		}
		carbonation
		color
		createdBy
		createdDate
		description
		foodPairingList {
			category
			title
		}
		guid
		hintsOfFlavorNotes
		id
		ingredients {
			title
			type
		}
		isHiddenExternally
		isNewProduct
		isVerified
		lastUpdatedBy
		locationRelationships {
			available
			canned
			growler
			# location { ...location }
			onTap
			# product { ...product }
		}
		locationsAvailable {
			available
			canned
			growler
			# location { ...location }
			onTap
			# product { ...product }
		}
		primaryFlavorNotes
		releaseDate
		servingTemperature
		servingVessels
		slug
		style {
			abvMax
			abvMin
			acidity
			alcohol
			alcoholBase
			alcoholFree
			appearance
			appleVariety
			baseSpirit
			beanRegion
			beanType
			body
			brixCount
			carbonation
			# category { ...category }
			ciderColor
			ciderFamily
			cocktailColor
			coffeePreparation
			colorIntensity
			createdBy
			createdDate
			description
			fgMax
			fgMin
			finish
			flavorIntensity
			# foodPairingList { ...foodPairingList }
			fruitOrigin
			garnish
			grapesOrigin
			hintsOfFlavorNotes
			honeyVariety
			hops
			ibuMax
			ibuMin
			id
			kegFreshnessDuration
			kombuchaFamily
			label
			lastUpdatedBy
			malt
			meadColor
			mixer
			ogMax
			ogMin
			pearVariety
			perceivedBitterness
			perceivedProductBitterness
			perceivedSaltiness
			perceivedSourness
			perceivedSpiciness
			perceivedSweetness
			primaryFlavorNotes
			probiotics
			process
			productType
			roastProfile
			seltzerColor
			servingTemperature
			servingVessels
			slug
			sourness
			srmMax
			srmMin
			tannin
			teaCharacter
			title
			updatedDate
			vintage
			wineColor
			yeast
		}
		tastingExperience
		title
		traits {
			allergens
			availability
			# nutrition { ...nutrition }
			otherTraits
		}
		updatedDate
		vintage
		... on Unverified {
        	ppSubId
		}
	}
}
`
