import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../state/store';

const orgState = (state: RootState) => state.activeOrgGroup;

const selectActiveOrgGroupSlug = createSelector(orgState, ({ slug }) => slug);
const selectActiveBmSubId = createSelector(orgState, ({ bmSubId }) => bmSubId);
const selectActiveBrandId = createSelector(orgState, ({ brandId }) => brandId);
const selectActivePpSubId = createSelector(orgState, ({ ppSubId }) => ppSubId);
const selectActiveTitle = createSelector(orgState, ({ title }) => title);
const selectActiveId = createSelector(orgState, ({ id }) => id);
const selectActiveCloudinaryAssetId = createSelector(orgState, ({ cloudinaryAssetId }) => cloudinaryAssetId);

export {
  selectActiveBmSubId,
  selectActiveBrandId,
  selectActiveCloudinaryAssetId,
  selectActiveId,
  selectActiveOrgGroupSlug,
  selectActivePpSubId,
  selectActiveTitle,
};