import { KegStatus } from "types";
import { useGetInventoryKegsQuery } from "../../api";
import { selectActivePpSubId } from "../../features/orgGroups/state/orgGroupSelectors";
import { useAppSelector } from "../../state/store";

export const useKegsOnPPSub = () => {
	const ppSubId = useAppSelector(selectActivePpSubId);
	const { data: assignedKegs, isLoading: assignedKegsLoading } =
		useGetInventoryKegsQuery({
			ppSubId,
			kegStatus: KegStatus.Assigned,
		});
	const { data: unassignedKegs, isLoading: unassignedKegsLoading } =
		useGetInventoryKegsQuery({
			ppSubId,
			kegStatus: KegStatus.Unassigned,
		});
	const { data: archivedKegs, isLoading: archivedKegsLoading } =
		useGetInventoryKegsQuery({
			ppSubId,
			kegStatus: KegStatus.Depleted,
		});
	const { data: tappedKegs, isLoading: tappedKegsLoading } =
		useGetInventoryKegsQuery({
			ppSubId,
			kegStatus: KegStatus.Tapped,
		});

	return {
		assignedKegs,
		unassignedKegs,
		archivedKegs,
		tappedKegs,
		isLoading:
			assignedKegsLoading ||
			unassignedKegsLoading ||
			archivedKegsLoading ||
			tappedKegsLoading,
	};
};
