import { OrganizationGroup } from 'types';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OrgGroupState {
    slug: string
    bmSubId: OrganizationGroup["bmSubId"]
    brandId: OrganizationGroup["brandId"]
    ppSubId: OrganizationGroup["ppSubId"]
    title: OrganizationGroup["title"]
    id: OrganizationGroup["id"]
    cloudinaryAssetId: OrganizationGroup["cloudinaryAssetId"]
}

const initialState: OrgGroupState = {
    slug: "",
    bmSubId: "",
    brandId: "",
    ppSubId: "",
    title: "",
    id: "",
    cloudinaryAssetId: ""
}

const orgGroupSlice = createSlice({
    name: "active-org-group",
    initialState,
    reducers: {
        setActiveOrgGroup: (state, action: PayloadAction<OrganizationGroup>) => {
            state.bmSubId = action.payload.bmSubId ?? ""
            state.brandId = action.payload.brandId ?? ""
            state.cloudinaryAssetId = action.payload.cloudinaryAssetId ?? ""
            state.id = action.payload.id ?? ""
            state.ppSubId = action.payload.ppSubId ?? ""
            state.slug = action.payload.slug ?? ""
            state.title = action.payload.title ?? ""
        },
        clearActiveOrgGroup: () => {
            return initialState
        }
    }
})

export const { setActiveOrgGroup, clearActiveOrgGroup } = orgGroupSlice.actions

export default orgGroupSlice
