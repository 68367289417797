"use client"

import { useEffect } from "react"
import { BrewMenuSubscription, User } from "types"

import { useLazyGetBLXUserQuery } from "../../api"
import { useAppSelector } from "../store"
import { activeBmaSub, activeBrandSub, activeSubs, sessionUser } from "./persistedUserSlice"
import { IUserActions, IUserState, userActions, userState } from "./userState"

export interface UseUserHook extends Partial<IUserState & IUserActions> {
    userData: User
    activeBmaSubId: string
    isFetchingUserData: boolean
    activeBrandId: string
    activePPSubId: string
    sessionUser: User
    associatedBMSubscriptions: BrewMenuSubscription[]
}

/**
 * @deprecated since 2023-10-01
 * This hook is deprecated. Please use the useBrewlogixUser.ts hook instead.
 * Avoid using any state from userState.ts as it will be removed in future versions.
 */
export const useBLXUser = (user?: User): UseUserHook => {
    const [getUser] = useLazyGetBLXUserQuery()

    const associatedPPSubscriptions = userState.use.associatedPPSubscriptions()
    const associatedBrands = userState.use.associatedBrands()
    const associatedBMSubscriptions = userState.use.userBMAssociations()

    const resetUserState = userState.use.resetUserState()
    const populateUserState = userActions.populateUserState

    // RTK User State
    const userData = useAppSelector(sessionUser)
    const activePPSubId = useAppSelector(activeSubs).pp
    const activeBrandId = useAppSelector(activeBrandSub)
    const activeBmaSubId = useAppSelector(activeBmaSub)

    useEffect(() => {
        if (userData && userData?.id.length > 0) {
            populateUserState({ user: userData })
        } else {
            ;(async () => {
                const userRes = await getUser().unwrap()
                if (userRes.id) {
                    populateUserState({ user: userRes })
                }
            })()
        }

        return () => {
            resetUserState()
        }
    }, [userData])

    // Zustand User State
    const appAccess = userState.use.appAccess()
    const orgId = userState.use.orgId()
    const orgRole = userState.use.orgRole()
    const userRole = userState.use.userRole()

    return {
        sessionUser: userData,
        activeBmaSubId,
        activeBrandId,
        activePPSubId,
        resetUserState,
        userData: userData,
        appAccess,
        associatedBrands,
        associatedPPSubscriptions,
        associatedBMSubscriptions,
        isFetchingUserData: false,
        orgId,
        orgRole,
        userRole
    }
}
