"use client"

import React from "react"

import { datadogLogs, LogsGlobal } from "@datadog/browser-logs"

export const DatadogLogsContext = React.createContext(null)

export const DatadogLogsProvider = ({ children, shouldInit }) => {
    const logs = React.useRef<LogsGlobal | null>(null)

    const environment = process.env.VERCEL_ENV !== "production" ? "develepment" : "production"

    const initializeLogs = React.useCallback((): LogsGlobal => {
        if (!logs.current && shouldInit) {
            datadogLogs.init({
                clientToken: String(process.env.NEXT_PUBLIC_DATADOG_BLAP_CLIENT_TOKEN),
                site: "datadoghq.com",
                service: "blx-app",
                env: String(environment),
                version: String(process.env.NEXT_PUBLIC_APP_VERSION),
                sessionSampleRate: 100,
                forwardErrorsToLogs: true
            })

            logs.current = datadogLogs
        }

        return logs.current
    }, [shouldInit]) // Dependency on shouldInit

    const getLogs = React.useCallback((): LogsGlobal => {
        if (!logs.current && shouldInit) {
            return initializeLogs()
        }
        return logs.current
    }, [initializeLogs, shouldInit]) // Dependency on initializeLogs and shouldInit

    return <DatadogLogsContext.Provider value={getLogs}>{children}</DatadogLogsContext.Provider>
}
