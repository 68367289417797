"use client";
import { PerformancePlatformSubscription } from "types";
import { useGetPPSubscriptionQuery } from "../../api";
import { selectActivePpSubId } from "../../features/orgGroups/state/orgGroupSelectors";
import { useAppSelector } from "../store";

export interface UsePPSub {
	isFetchingPPSub: boolean;
	ppSubId: string;
	isSuccessPPSub: boolean;
	isLoadingPPSub: boolean;
	isPPSubError: boolean;
	ppSubApiData: PerformancePlatformSubscription;
}

export const usePPSub = (): UsePPSub => {
	const ppSubId = useAppSelector(selectActivePpSubId);

	const {
		data,
		isError: isPPSubError,
		isFetching: isFetchingPPSub,
		isLoading: isLoadingPPSub,
		isSuccess: isSuccessPPSub,
	} = useGetPPSubscriptionQuery({ ppSubId: ppSubId }, { skip: !ppSubId });

	return {
		isSuccessPPSub,
		isLoadingPPSub,
		isPPSubError,
		ppSubApiData: data,
		isFetchingPPSub,
		ppSubId,
	};
};
