import {
    BmSubscriptionAssociation,
    Brand,
    BrandAssociation,
    BrewMenuSubscription,
    PerformancePlatformRole,
    PerformancePlatformSubscription,
    PpSubscriptionAssociation,
    User,
    UserAssociationRole
} from "types"
import { BLXApplications } from "types/src/utility/routing"
import { IOption } from "types/src/utility/ui"
import { create } from "zustand"
import { devtools } from "zustand/middleware"

import { brandApi, brewMenuApi, ppSubApi } from "../../api"
import { createSelectors } from "../../utilities"
import { blxQ } from "../store"

interface IUserState {
    user: User
    sessionToken: string
    // TODO: Define role as blxRole - extend this list
    userRole: PerformancePlatformRole | "superadmin" | "viewer" | "admin"
    // currentSubscription: PpSubscriptionAssociation
    associatedPPSubscriptions: PerformancePlatformSubscription[]
    // activePPSubId: string
    // MMB
    associatedBrands: Brand[]
    currentBrandAssociation: BrandAssociation
    userBMAssociations: BrewMenuSubscription[]
    // activeBrandId: string
    activeBrand: Brand
    appAccess: BLXApplications[]
    ppSubscriptionOptions: IOption[]
    brandOptions: IOption[]
    orgId: string
    orgRole: UserAssociationRole
}

interface IUserActions {
    setUser: (user: User) => void
    resetUserState: () => void
    populateUserState: ({
        user,
        currentPPSubId,
        currentBrandId
    }: {
        user: User
        currentPPSubId?: string
        currentBrandId?: string
    }) => Promise<boolean>
    setUserPPSubAssociations: (ppSubAssociation: PerformancePlatformSubscription) => void
    // setActivePPSubId: (ppSubId: string) => void
    setUserBrandAssociations: (brandAssociation: Brand) => void
    setUserBMAssociations: (bmAssociation: BrewMenuSubscription) => void
    // setActiveBrandId: (brandId: string) => void
    setActiveBrand: (brand: Brand) => void
    parseAppAccess: (user: User) => BLXApplications[]
}

const initialValues: IUserState = {
    user: null,
    userRole: null,
    orgRole: null,
    sessionToken: "",
    // activePPSubId: '',
    // currentSubscription: null,
    associatedPPSubscriptions: [],
    userBMAssociations: [],
    associatedBrands: [],
    currentBrandAssociation: null,
    // activeBrandId: '',
    activeBrand: null,
    appAccess: [],
    ppSubscriptionOptions: [],
    brandOptions: [],
    orgId: ""
}

/**
 * @deprecated This code is deprecated and will be removed in future releases.
 * Date: 2023-10-05
 */
const userState = createSelectors(
    create<IUserState & IUserActions>()(
        devtools(
            (set, get) => ({
                ...initialValues,
                setUser: (user) => {
                    set({ user })
                },
                setActiveBrand: (brand) => {
                    userState.setState({ activeBrand: brand })
                    brandApi.util.invalidateTags(["Brand"])
                },
                // setActiveBrandId(brandId) {
                //     userState.setState({ activeBrandId: brandId })
                //     brandApi.util.invalidateTags(['Brand'])
                // },
                setUserBrandAssociations: (brand) => {
                    const brands = userState.getState().associatedBrands
                    if (brand?.id) {
                        brands.push(brand)
                    }

                    userState.setState({ associatedBrands: brands })
                },
                // setActivePPSubId(ppSubId) {
                //     set({ activePPSubId: ppSubId })
                //     // ppSubApi.util.invalidateTags(['PPSub', 'Kegs'])
                // },
                setUserPPSubAssociations: (sub) => {
                    const subscriptions = get().associatedPPSubscriptions
                    if (sub?.id) {
                        subscriptions.push(sub)
                    }
                    set({ associatedPPSubscriptions: [...subscriptions] })
                },
                setUserBMAssociations: (bmSub) => {
                    const subscriptions = get().userBMAssociations
                    if (bmSub?.id) {
                        subscriptions.push(bmSub)
                    }
                    set({ userBMAssociations: [bmSub] })
                },
                parseAppAccess: (user) => {
                    if (user?.brewlogixSuperadmin) {
                        return Object.keys(BLXApplications).map((app) => BLXApplications[app])
                    } else {
                        const appAccess: BLXApplications[] = [BLXApplications.BrewKnowledge]
                        if (user?.ppSubscriptions?.length > 0)
                            appAccess.push(BLXApplications.Operations)
                        if (user?.mmbBrands?.length > 0) appAccess.push(BLXApplications.Marketing)

                        return appAccess
                    }
                },
                populateUserState: async ({ user, currentPPSubId }) => {
                    if (!user?.id) return

                    // const associatedPPSubscriptions =
                    // 	await getUserAssociatedPPSubscriptions(
                    // 		user?.ppSubscriptions,
                    // 		user?.brewlogixSuperadmin,
                    // 	);
                    // const ppSubscriptionOptions = associatedPPSubscriptions?.map(
                    // 	(ppSub): IOption => ({
                    // 		label: ppSub.title,
                    // 		value: ppSub.id,
                    // 	}),
                    // );
                    // const brands = await getUserAssociatedBrands(user?.mmbBrands);
                    // const userBMAssociations = await getUserAssociatedBMSubscriptions(
                    // 	user?.bmSubscriptions,
                    // 	user?.brewlogixSuperadmin,
                    // );

                    const userRole = user?.brewlogixSuperadmin
                        ? "superadmin"
                        : user.ppSubscriptions[0]?.role
                    const appAccess = get().parseAppAccess(user)

                    // const currentSubscription =
                    //     (user?.ppSubscriptions &&
                    //         user?.ppSubscriptions.find(
                    //             (ppSub) => ppSub?.ppSubId === user?.defaultSubscription
                    //         )) ||
                    //     user?.ppSubscriptions?.[0]

                    set({
                        user,
                        userRole,
                        appAccess,
                        orgRole: user?.organization?.role,
                        orgId: user?.organization?.organizationId,
                        // currentSubscription: currentSubscription,
                        // activePPSubId:
                        //     currentSubscription?.ppSubId ||
                        //     currentPPSubId ||
                        //     user.defaultSubscription,
                        // associatedPPSubscriptions,
                        // ppSubscriptionOptions,
                        // userBMAssociations,
                        // associatedBrands: brands,
                        // activeBrandId: user?.mmbBrands?.[0]?.brandId,
                        currentBrandAssociation: user?.mmbBrands?.[0]
                    })
                    return true
                },
                resetUserState: () => {
                    set({ ...initialValues })
                }
            }),
            { name: "user" }
        )
    )
)

const userActions = {
    setUser: userState.getState().setUser,
    resetUserState: userState.getState().resetUserState,

    setUserPPSubAssociations: userState.getState().setUserPPSubAssociations,
    setUserBMAssociations: userState.getState().setUserBMAssociations,
    setUserBrandAssociations: userState.getState().setUserBrandAssociations,
    populateUserState: userState.getState().populateUserState
}

export { userActions, userState }
export type { IUserActions, IUserState }

export const getUserAssociatedPPSubscriptions = async (
    ppSubAssociations: PpSubscriptionAssociation[],
    isSuperAdmin?: boolean
): Promise<PerformancePlatformSubscription[]> => {
    const subscriptions = []

    await Promise.all(
        ppSubAssociations.map(async (sub) => {
            const res = await blxQ(
                ppSubApi.endpoints.getAssociatedSubscription.initiate({
                    ppSubId: sub?.ppSubId
                })
            )
            if (res?.isSuccess) {
                if (!subscriptions.find((sub) => sub?.id === res.data?.id)) {
                    return subscriptions.push(res.data)
                }
            }
            if (res.isError) {
                console.log(res.error)
            }
        })
    )

    return subscriptions.filter((item: PerformancePlatformSubscription) => item?.id)
}
export const getUserAssociatedBMSubscriptions = async (
    bmSubAssociations: BmSubscriptionAssociation[],
    isSuperAdmin?: boolean
): Promise<BrewMenuSubscription[]> => {
    const subscriptions: BrewMenuSubscription[] = []

    await Promise.all(
        bmSubAssociations.map(async ({ bmSubId }) => {
            const { data, error, isError, isSuccess } = await blxQ(
                brewMenuApi.endpoints.getBrewMenuSubscriptionById.initiate({
                    bmSubId
                })
            )
            if (isSuccess) {
                if (!subscriptions.find((sub) => sub?.id === data?.id)) {
                    return subscriptions.push(data)
                }
            }
            if (isError) {
                console.log(error)
            }
        })
    )
    return subscriptions.filter((item: BrewMenuSubscription) => item?.id)
}

export const getSuperAdminPPSubscriptions = async (): Promise<
    PerformancePlatformSubscription[]
> => {
    const { data, error, isError, isSuccess } = await blxQ(
        ppSubApi.endpoints.superAdminGetPPSubscriptions.initiate({ limit: 500 })
    )
    if (isError) {
        console.log(error)
        return null
    }
    return data?.items
}
