"use client";

import { Brand } from "types";
import { useGetBrandByIdQuery } from "../../api";
import { useAppSelector } from "../store";
import { activeSubs } from "../user";

export interface UseBrand {
  isFetchingBrand: boolean;
  brand: Brand;
  brandId: string;
  refetchBrand: () => void;
  isSuccessBrand: boolean;
  isLoadingBrand: boolean;
  isBrandError: boolean;
  brandApiData: Brand;
}

export const useBrand = (brandId?: string): UseBrand => {
  const activeBrandId = useAppSelector(activeSubs).brand;

  const {
    data: brandApiData,
    isError: isBrandError,
    isFetching: isFetchingBrand,
    isLoading: isLoadingBrand,
    isSuccess: isSuccessBrand,
    refetch: refetchBrand,
  } = useGetBrandByIdQuery(
    { itemId: brandId || activeBrandId },
    { skip: !brandId },
  );

  return {
    refetchBrand,
    isSuccessBrand,
    isLoadingBrand,
    isBrandError,
    brandApiData,
    isFetchingBrand,
    brand: brandApiData,
    brandId,
  };
};
