import { skipToken } from "@reduxjs/toolkit/query";
import { useGetBrewMenusByBMSubIdQuery } from "../../../api/brewMenu/brewMenuMenuApi";
import { selectActiveBmSubId } from "../../../features/orgGroups/state/orgGroupSelectors";
import { useAppSelector } from "../../../state/store";
import { brewMenuSubscriptionToken } from "../utilities/brewMenuSubscriptionToken";

export const useBrewMenuDisplayMenuList = () => {
	const bmSubId = useAppSelector(selectActiveBmSubId);

	const { data, isError, isFetching, isLoading, isSuccess } =
		useGetBrewMenusByBMSubIdQuery(
			brewMenuSubscriptionToken(bmSubId) ?? skipToken,
		);

	return {
		menuList: data?.items || [],
		isErrorBrewMenuDisplayMenuList: isError,
		isFetchingBrewMenuDisplayMenuList: isFetching,
		isLoadingBrewMenuDisplayMenuList: isLoading,
		isSuccessBrewMenuDisplayMenuList: isSuccess,
	};
};
