"use client"

import { skipToken } from "@reduxjs/toolkit/query"
import { useEffect } from "react"
import { Organization } from "types"
import { useGetOrganizationByIdQuery } from "../../api"
import { useAppSelector } from "../store"
import { sessionUser, userState } from "../user"

export interface UseOrganization {
  isFetchingOrganization: boolean
  organization: Organization
  organizationId: string
  refetchOrganization: () => void
  isSuccessOrganization: boolean
  isLoadingOrganization: boolean
  isOrganizationError: boolean
}

const token = (organizationId: string): { organizationId: string } | undefined => {
  if (organizationId && organizationId.length > 0) return { organizationId }

  return undefined
}

export const useOrganization = (): UseOrganization => {
  const orgId = useAppSelector(sessionUser)?.organization?.organizationId
  const activeOrganizationId = userState((state) => state.orgId)

  const {
    data: organization,
    isError: isOrganizationError,
    isFetching: isFetchingOrganization,
    isLoading: isLoadingOrganization,
    isSuccess: isSuccessOrganization,
    refetch: refetchOrganization
  } = useGetOrganizationByIdQuery(token(orgId) ?? skipToken)

  useEffect(() => {
    if (activeOrganizationId !== orgId || !activeOrganizationId) {
      if (isSuccessOrganization) refetchOrganization()
    }
  }, [orgId])

  return {
    refetchOrganization,
    isSuccessOrganization,
    isLoadingOrganization,
    isOrganizationError,
    isFetchingOrganization,
    organization,
    organizationId: orgId
  }
}
