"use client";
import { Keg } from "types";
import { useGetAssignedKegsQuery } from "../../../api/inventoryApi";
import { selectActivePpSubId } from "../../../features/orgGroups/state/orgGroupSelectors";
import { useAppSelector } from "../../store";
import {
	InventoryState,
	inventoryActions,
	inventoryState,
} from "../inventoryState";

export interface UseAssignedKegsHook extends Partial<InventoryState> {
	isFetching: boolean;
	isLoading: boolean;
	isSuccessAssignedKegs: boolean;
	isError: boolean;
	assignedKegs: Keg[];
}

export const useAssignedKegs = (): UseAssignedKegsHook => {
	const selectedKeg = inventoryState.use.selectedKeg();
	const selectKeg = inventoryActions.selectKeg;

	const ppSubId = useAppSelector(selectActivePpSubId);

	const {
		data: assignedKegs,
		isLoading,
		isError,
		isFetching,
		isSuccess,
	} = useGetAssignedKegsQuery({ ppSubId }, { skip: !ppSubId });

	return {
		assignedKegs,
		selectKeg,
		selectedKeg,
		isLoading,
		isSuccessAssignedKegs: isSuccess,
		isError,
		isFetching,
	};
};
