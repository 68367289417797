"use client";

import { useGetMyOrganizationGroupsQuery } from "../api/orgGroupsApi";

export const useOrgGroups = () => {
	const { data, isFetching, isError, error, isSuccess } =
		useGetMyOrganizationGroupsQuery();

	return {
		groups: data ?? [],
		groupsAreLoading: false,
		groupsError: error,
		groupsHasError: isError,
		groupsAreFetching: isFetching,
		isSuccess,
	};
};
