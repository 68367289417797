import { skipToken } from "@reduxjs/toolkit/query";
import { useGetBrewMenusByBMSubIdQuery } from "../../../api/brewMenu/brewMenuMenuApi";
import { selectActiveBmSubId } from "../../../features/orgGroups/state/orgGroupSelectors";
import { useAppSelector } from "../../../state/store";
import { brewMenuSubscriptionToken } from "../utilities/brewMenuSubscriptionToken";

export const useBrewMenuMenuList = () => {
	const bmSubId = useAppSelector(selectActiveBmSubId);

	const { data, isError, isFetching, isLoading, isSuccess, refetch } =
		useGetBrewMenusByBMSubIdQuery(
			brewMenuSubscriptionToken(bmSubId) ?? skipToken,
		);

	return {
		menuList: data?.items || [],
		getAllBMMenuRefetch: refetch,
		isErrorBrewMenuMenuList: isError,
		isFetchingBrewMenuMenuList: isFetching,
		isLoadingBrewMenuMenuList: isLoading,
		isSuccessBrewMenuMenuList: isSuccess,
	};
};
