import { API } from "aws-amplify";
import _ from "lodash";
import { toastActions } from "notifications/src/toasts/state/useToasts";
import {
	InputGetByPpSubId,
	PerformancePlatformSubscription,
	TapSensor,
} from "types";

import { GraphQLSubscription } from "@aws-amplify/api";

import { getTapSensorByIdQuery } from "../queries/private/tapSensors/getTapSensorById";
import { getTapSensorsFromPPSub } from "../queries/private/tapSensors/getTapSensorsFromPPSub";
import { tapSensorEventsObservable } from "../queries/private/tapSensors/tapSensorUpdatesObservable";
import { getAToken } from "../utilities";
import { baseApi } from "./baseApi";

const tapSensorsApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getPPSubById */
		getTapSensorById: builder.query<TapSensor, InputGetByPpSubId>({
			providesTags: ["TapSensor"],
			query: (input) => ({
				query: getTapSensorByIdQuery,
				args: input,
			}),

			// @ts-ignore TODO: Fix this
			transformResponse: (data: TapSensor): TapSensor => {
				toastActions.addToast({ title: "Tap sensor queried" });
				return data;
			},
		}),
		/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getPPSubById */
		getTapSensorsFromPPSub: builder.query<TapSensor[], InputGetByPpSubId>({
			providesTags: (result) =>
				result
					? [
							...result.map(({ id }) => ({ type: "TapSensors" as const, id })),
							{ type: "TapSensors", id: "LIST" },
							"TapSensors",
						]
					: [{ type: "TapSensors", id: "LIST" }, "TapSensors"],

			query: (input) => ({
				pollingInterval: 120000,
				query: getTapSensorsFromPPSub,
				args: input,
			}),
			onCacheEntryAdded: async (
				arg,
				{ updateCachedData, cacheDataLoaded, cacheEntryRemoved },
			) => {
				let sub;
				try {
					await cacheDataLoaded;
					const token = await getAToken();
					sub = API.graphql<GraphQLSubscription<TapSensor>>({
						query: tapSensorEventsObservable,
						variables: {
							input: {
								ppSubId: arg.ppSubId,
							},
						},
						authMode: "AWS_LAMBDA",
						authToken: token,
					}).subscribe({
						next: ({ value }) => {
							updateCachedData((draft) => {
								const updatedTapSensor: TapSensor =
									value?.data?.tapSensorEvents;
								const index = draft.findIndex(
									(tapSensor) => tapSensor.id === updatedTapSensor.id,
								);
								if (index !== -1) {
									_.merge(draft[index], updatedTapSensor);
								} else {
									draft.push(updatedTapSensor);
								}
							});
						},
						error: (error) =>
							console.error("Error with tap sensors observable:", error),
					});
				} catch {}

				await cacheEntryRemoved;
				sub?.unsubscribe();
			},
			transformResponse: (
				data: PerformancePlatformSubscription,
			): TapSensor[] => {
				return data?.tapSensors || [];
			},
		}),
	}),
});

const {
	useGetTapSensorByIdQuery,
	useLazyGetTapSensorByIdQuery,
	useGetTapSensorsFromPPSubQuery,
	useLazyGetTapSensorsFromPPSubQuery,
} = tapSensorsApi;

export {
	tapSensorsApi,
	useGetTapSensorByIdQuery,
	useGetTapSensorsFromPPSubQuery,
	useLazyGetTapSensorByIdQuery,
	useLazyGetTapSensorsFromPPSubQuery,
};
