import { useGetBrewMenuByIdQuery } from "../../../api";
import { selectActiveBmSubId } from "../../../features/orgGroups/state/orgGroupSelectors";
import { useAppSelector } from "../../../state/store";

export const useBrewMenuMenuById = (menuId: string) => {
	const bmSubId = useAppSelector(selectActiveBmSubId);
	const { data, isError, isFetching, isLoading, isSuccess, refetch } =
		useGetBrewMenuByIdQuery(
			{
				itemId: menuId,
				parentId: bmSubId,
			},
			{ skip: !menuId || !bmSubId },
		);

	return {
		menu: data,
		sections: data?.sections || [],
		getBrewMenuRefetch: refetch,
		isErrorBrewMenuMenuById: isError,
		isFetchingBrewMenuMenuById: isFetching,
		isLoadingBrewMenuMenuById: isLoading,
		isSuccessBrewMenuMenuById: isSuccess,
	};
};
