"use client"
import { useEffect, useState } from "react"
import { CompositeProduct, } from "types"
import { LimitedProductSearchInput, useLimitedProductSearchQuery } from "../../../api"

interface IPaginatedLimitedProductSearch {
    data: { items: CompositeProduct[]; nextToken?: string }
    isLoading: boolean
}

export const usePaginatedLimitedProductSearch = ({
    searchTerm = "",
    nextToken = "",
    limit = 25,
    brewType,
    order,
    orderBy
}: LimitedProductSearchInput) => {
    const [allItems, setAllItems] = useState<CompositeProduct[]>([])
    const shouldSkip: boolean = !nextToken && !searchTerm

    const { data: searchResults, isLoading } =
        useLimitedProductSearchQuery({
            searchTerm,
            limit: limit || 25,
            brewType,
            order,
            orderBy,
            nextToken,
        }, {
            skip: shouldSkip
        })

    const { items, nextToken: newNextToken } = searchResults || {}

    useEffect(() => {
        if (!searchTerm) {
            setAllItems([])
        }

        if (items) {
            setAllItems((prevItems: CompositeProduct[]) => [...prevItems, ...items])
        }
    }, [items])

    return {
        items: allItems,
        isLoading,
        newNextToken
    }
}
