"use client";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { Keg } from "types";
import { useGetInventoryKegsQuery } from "../../../api";
import { selectActivePpSubId } from "../../../features/orgGroups/state/orgGroupSelectors";
import { useAppSelector } from "../../store";
import { InventoryState } from "../inventoryState";

export interface UseInventoryKegsHook extends Partial<InventoryState> {
	isFetching: boolean;
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	status: QueryStatus;
	inventoryKegs: Keg[];
}

export const useInventoryKegs = (): UseInventoryKegsHook => {
	const ppSubId = useAppSelector(selectActivePpSubId);

	const { data, isLoading, isError, isFetching, isSuccess, status } =
		useGetInventoryKegsQuery({ ppSubId: ppSubId }, { skip: !ppSubId });

	return {
		inventoryKegs: data,
		isLoading,
		isSuccess,
		isError,
		isFetching,
		status,
	};
};
