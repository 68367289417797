import { toastActions } from "notifications/src/toasts";
import {
	CloseLocation,
	CreateLocation,
	DeleteLocation,
	InputGetById,
	InputGetBySlug,
	InputGetLocationProducts,
	InputLocationProductRelationship,
	Location,
	LocationField,
	LocationProductRelationship,
	LocationProductRelationshipConnection,
	LocationStatus,
	OpenSearchType,
	Order,
	UpdateLocation,
} from "types";
import { closeLocationMutation } from "../mutations/private/location/closeLocationMutation";
import { createLocation } from "../mutations/private/location/createLocationMutation";
import { deleteLocationMutation } from "../mutations/private/location/deleteLocationMutation";
import { updateLocation } from "../mutations/private/location/updateLocationMutation";
import { updateLocationProductRelationshipMutation } from "../mutations/private/location/updateLocationProductRelationship-MMBlocations";
import { getLocationById } from "../queries/private/location/getLocationById";
import { getLocationBySlugQuery } from "../queries/private/location/getLocationBySlugQuery";
import { getLocationProductsQuery } from "../queries/private/location/getLocationProductsQuery";
import { searchBPLLocations } from "../queries/private/location/searchBPLLocations";
import { baseApi } from "./baseApi";

const locationApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		searchAllLocations: builder.query<Location[], string>({
			providesTags: ["Locations"],
			queryFn: async (arg, _api, _extraOptions, baseQuery) => {
				let nextToken = undefined;
				let results: Location[] = [];
				do {
					const response = await baseQuery({
						query: searchBPLLocations,
						args: {
							nextToken,
							limit: nextToken ? Math.min(750, 10000 - Number(nextToken)) : 750,
							typeFilters: [OpenSearchType.Location],
							search: {
								wildcardText: arg,
							},
						},
					});
					results = [...results, ...response.data.items];
					const newNextToken = response?.data?.nextToken;
					if (Number(newNextToken) > Number(nextToken || 0)) {
						nextToken = newNextToken;
					} else {
						nextToken = undefined;
					}
				} while (nextToken);
				return { data: results };
			},
		}),
		/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getLocationBySlug  */
		getLocationBySlug: builder.query<Location, InputGetBySlug>({
			//! TODO NEEDS: TESTING
			providesTags: ["Location"],
			query: (input) => ({
				query: getLocationBySlugQuery,
				args: { ...input },
			}),
			transformResponse: (data: Location): Location => {
				// TODO: Add notifications
				//! TODO NEEDS: locationActions
				return data;
			},
			transformErrorResponse: (error) => {
				// TODO: Add notifications
				console.error("RTKQ/transformErrorResponse/getLocationBySlug", error);
				return error;
			},
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/query/getLocationProducts */
		getLocationProducts: builder.query<
			LocationProductRelationshipConnection,
			InputGetLocationProducts
		>({
			// ! TODO NEEDS: TESTING
			providesTags: ["Location", "Products"],
			query: (input) => ({
				query: getLocationProductsQuery,
				args: input,
			}),
			transformResponse: (
				data: LocationProductRelationshipConnection,
			): LocationProductRelationshipConnection => {
				// TODO: Add notifications
				//! TODO NEEDS: locationActions
				return data;
			},
			transformErrorResponse: (error) => {
				// TODO: Add notifications
				console.error("RTKQ/transformErrorResponse/getLocationProducts", error);
				return error;
			},
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createLocation */
		createLocation: builder.mutation<Location, CreateLocation>({
			invalidatesTags: ["Locations"],
			query: (input) => ({
				query: createLocation,
				args: { ...input },
			}),
			transformResponse: (data: Location): Location => {
				toastActions.addToast({
					title: "Location successfully created",
					description: "Location successfully created",
					variant: "success",
					duration: 3000,
				});
				//! TODO NEEDS: locationActions
				return data;
			},
			transformErrorResponse: (error) => {
				toastActions.addToast({
					title: "Error",
					description: "Unable to save Location",
					variant: "error",
					duration: 3000,
				});
				console.error("RTKQ/transformErrorResponse/createLocation", error);
				return error;
			},
		}),
		/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateLocation */
		updateLocation: builder.mutation<Location, UpdateLocation>({
			//! TODO NEEDS: TESTING
			invalidatesTags: ["Location", "Locations"],
			query: (input) => ({
				query: updateLocation,
				args: { ...input },
			}),
			transformErrorResponse: (error) => {
				// TODO: Add notifications
				console.error("RTKQ/transformErrorResponse/updateLocation", error);
				return error;
			},
		}),
		updateLocationProductRealtionship: builder.mutation<
			LocationProductRelationship,
			InputLocationProductRelationship
		>({
			//! TODO NEEDS: TESTING
			query: (input) => ({
				query: updateLocationProductRelationshipMutation,
				args: input,
			}),
			transformResponse: (data: any): any => {
				toastActions.addToast({
					title: "Product Added",
					description: "Product Added",
					variant: "success",
					duration: 3000,
				});
				return data as LocationProductRelationship[];
			},
			transformErrorResponse: (error) => {
				toastActions.addToast({
					title: "Add Product Failed",
					description: "Add Product Failed",
					variant: "error",
					duration: 3000,
				});
				console.error(
					"RTKQ/transformErrorResponse/updateLocationProductRelationship",
					error,
				);
				return error;
			},
		}),
		getLocationById: builder.query<Location, InputGetById>({
			providesTags: ["Location"],
			query: (input) => ({
				query: getLocationById,
				args: { ...input },
			}),
		}),
		deleteLocation: builder.mutation<void, DeleteLocation>({
			invalidatesTags: ["Locations"],
			query: (args) => ({
				query: deleteLocationMutation,
				args,
			}),
		}),
		searchLocationPage: builder.query<
			{ locations: Location[]; numPages: number },
			{
				searchTerm: string;
				page: number;
				order: Order;
				orderBy: LocationField;
				status?: LocationStatus | undefined;
			}
		>({
			providesTags: ["Locations"],
			queryFn: async (arg, api, extraOptions, baseQuery) => {
				const result = await baseQuery({
					query: searchBPLLocations,
					args: {
						nextToken: String(arg.page * 10),
						limit: 10,
						typeFilters: [OpenSearchType.Location],
						search: {
							wildcardText: arg.searchTerm,
							searchFieldsLocations: {
								status: arg.status,
							}
						},
						order: arg.order,
						orderBy: arg.orderBy,
					},
				});
				return {
					data: {
						locations: result.data?.items || [],
						numPages: Math.ceil((result.data?.totalResults || 0) / 10),
					},
				};
			},
		}),
		closeLocation: builder.mutation<{ id: string }, CloseLocation>({
			invalidatesTags: ["Location", "Locations"],
			query: (args) => ({
				query: closeLocationMutation,
				args,
			}),
		}),
	}),
});

const {
	useSearchAllLocationsQuery,
	useCreateLocationMutation,
	useGetLocationBySlugQuery,
	useGetLocationProductsQuery,
	useLazyGetLocationBySlugQuery,
	useLazyGetLocationProductsQuery,
	useUpdateLocationMutation,
	useGetLocationByIdQuery,
	useDeleteLocationMutation,
	useSearchLocationPageQuery,
	useCloseLocationMutation,
} = locationApi;

export {
	locationApi,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createLocation */
	useCreateLocationMutation,
	useDeleteLocationMutation,
	useGetLocationByIdQuery,
	/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getLocationBySlug  */
	useGetLocationBySlugQuery,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/query/getLocationProducts */
	useGetLocationProductsQuery,
	/**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getLocationBySlug  */
	useLazyGetLocationBySlugQuery,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/query/getLocationProducts */
	useLazyGetLocationProductsQuery,
	useSearchAllLocationsQuery,
	useSearchLocationPageQuery,
	/** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateLocation */
	useUpdateLocationMutation,
	useCloseLocationMutation,
};
