export const getBrandByIdPL = /* GraphQL */ `
query GetBrandById($input: inputGetById!) {
	getBrandById(input: $input){
		address {
			city
			country
			postalCode
			state
			street
			street2
		}
		assets {
			__typename
			alt
			bytes
			caption
			category
			createdBy
			createdDate
			height
			id
			lastUpdatedBy
			path
			shared
			tags
			type
			updatedDate
			width
			... on CloudinaryAsset {
                cloudinaryId
            }
		}
		baId
		contact {
			email
			phone
		}
		createdBy
		createdDate
		description
		displayTitle
		featuredBrew {
			abv
			alcoholFree
			carbonation
			color
			createdBy
			createdDate
			description
			# foodPairingList { ...foodPairingList }
			guid
			hintsOfFlavorNotes
			id
			# ingredients { ...ingredients }
			isHiddenExternally
			isNewProduct
			isVerified
			lastUpdatedBy
			primaryFlavorNotes
			releaseDate
			servingTemperature
			servingVessels
			slug
			tastingExperience
			title
			updatedDate
			vintage
		}
		guid
		id
		lastUpdatedBy
		locations {
			address {
				city
				country
				postalCode
				state
				street
				street2
			}
			completionPercentage
			createdBy
			createdDate
			description
			guid
			id
			lastUpdatedBy
			slug
			subtitle
			timezone
			title
			updatedDate
			yearClosed
			yearOpened
		}
		mediaLibrary {
			alt
			bytes
			caption
			category
			createdBy
			createdDate
			height
			id
			lastUpdatedBy
			path
			shared
			tags
			type
			updatedDate
			width
		}
		organization {
			cloudinaryAssetId
			createdBy
			createdDate
			externalId
			hubspotId
			id
			lastUpdatedBy
			slug
			status
			title
			updatedDate
		}
		products {
			abv
			alcoholFree
			carbonation
			color
			createdBy
			createdDate
			description
			# foodPairingList { ...foodPairingList }
			guid
			hintsOfFlavorNotes
			id
			isHiddenExternally
			isNewProduct
			isVerified
			lastUpdatedBy
			primaryFlavorNotes
			releaseDate
			servingTemperature
			servingVessels
			slug
			tastingExperience
			title
			updatedDate
			vintage
		}
		slug
		socialMedia {
			handle
			platform
		}
		subtitle
		title
		traits {
			brandStatus
			brandTypes
			brewTypes
			isContract
			isIndependent
			status
		}
		updatedDate
		website
		yearEstablished
	}
}
`;
